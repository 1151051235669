import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { Link } from "react-router-dom";

const SwiperEx = ({ list }) => {

   return (
      <Swiper
         className="sw_explore"
         modules={[Pagination]}
         pagination={{
            type: 'fraction',
         }}
         spaceBetween={10}
         slidesPerView={'auto'}
         speed={600}
      >
         {
            list.map(item => (
               <SwiperSlide className="item_wrap" key={item.key} data-slideid={item.key}>
                  <div className="meta_slide">
                     <Link to={`${item.url}`}>
                        <h3>{item.title}</h3>
                     </Link>
                     <div className="text">
                        <p>
                           {item.text}
                        </p>
                     </div>
                     <Link to={`${item.url}`} className="item__btn">
                        Read more
                     </Link>
                  </div>
                  <div className="item">
                     <div className="head">
                        <div className="item_head">
                           Duration: {item.meta.duration}
                        </div>
                        <div className="item_head">
                           Cost: {item.meta.cost}
                        </div>
                     </div>
                     <div className="image">
                        <img src={item.image.link} alt={item.image.alt} />
                     </div>
                     <a href={item.people.link} className="people">
                        <div className="avatar">
                           <img src={item.people.avatar} alt={item.people.alt} />
                        </div>
                        <div className="name">
                           {item.people.name}
                        </div>
                     </a>
                  </div>
               </SwiperSlide>
            ))
         }
      </Swiper >
   )
};

export default SwiperEx;
